var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
const trackerModuleInst = new EventTrackerModule(ahoy);
export default class event_tracker_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "pixelModule");
  }
  initialize() {
    this.pixelModule = trackerModuleInst;
    console.log("[EventTrackerController::initialize] this.pixel", this.pixelModule);
  }
  connect() {
    console.log("[EventTrackerController::connect] this.pixel", this.pixelModule);
  }
  track() {
    console.log("[EventTrackerController::track] pixel", this.pixelModule);
    console.log("[EventTrackerController::track] this.eventValueName", this.eventValueName);
    this.pixelModule.track(`[EventTrackerController::track] ${this.eventNameValue}`, {});
  }
}
__publicField(event_tracker_controller_default, "values", { eventName: String });
