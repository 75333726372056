var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
import { LinkModule } from "@/modules/link_module";
import { EventTrackerModule } from "@/modules/event_tracker_module";
import ahoy from "ahoy.js";
const linkModuleInst = new LinkModule();
export default class link_redirect_controller_default extends ApplicationController {
  constructor() {
    super(...arguments);
    __publicField(this, "trackerModule", new EventTrackerModule(ahoy));
    __publicField(this, "linkModule", linkModuleInst);
  }
  initialize() {
  }
  connect() {
  }
  interceptClickWithRedirect(event) {
    event.preventDefault();
    console.log("[LinkRedirectController::interceptClickWithRedirect]", event);
    const url = this.currentTabUrlValue;
    const target = this.targetValue;
    const newTabUrl = this.newTabUrlValue;
    console.log("url", url);
    console.log("target", target);
    console.log("newTabUrl", newTabUrl);
    this.trackRedirFire();
    if (newTabUrl && newTabUrl.length > 0) {
      this.trackNewTabFire();
      this.linkModule.performRedirect(this.linkModule.addCurrentParamsToUrl(newTabUrl), true);
    }
    if (url && url.length > 0) {
      const newTab = target === "_blank";
      this.trackCurrentTabFire();
      this.linkModule.performRedirect(this.linkModule.addCurrentParamsToUrl(url), newTab);
    }
  }
  trackRedirFire() {
    this.trackerModule.track("link_redirect_fire", {
      url_params: window.location.search,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  trackNewTabFire() {
    this.trackerModule.track("link_redirect_new_tab_fire", {
      url_params: window.location.search,
      page: window.location.pathname,
      url: window.location.href
    });
  }
  trackCurrentTabFire() {
    this.trackerModule.track("link_redirect_current_tab_fire", {
      url_params: window.location.search,
      page: window.location.pathname,
      url: window.location.href
    });
  }
}
__publicField(link_redirect_controller_default, "values", { currentTabUrl: String, newTabUrl: String, target: String });
